import React from 'react';
import './App.css';
import AppRouter from './components/routes/AppRouter';

function App(){
    return(
      <div className="App">
        <AppRouter />
      </div>
    )
}

export default App;
