import React, { useState, useEffect, Component } from "react";
import "../App.css";
import Tabs from "../components/Tabs";
import Modal from "../components/Modal";

import axios from "axios";

import { useAuth0 } from '../react-auth0-spa';
import NavBar from './NavBar';
import FootBar from './FootBar';

function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

function Catalogue({ history }) {
  const { isAuthenticated } = useAuth0();

  const [user, setUser] = useState(null);

  useEffect(() => {
    refreshList();
  }, []);


  // Randy things 11/22

  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState({item_name: "", pub_date: "", description: "", price: 0, displayed: false, category: 0, picture: ""});
  const [viewCompleted, setViewCompleted] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [delState, setDelState] = useState(false); 
  const [userState, setUserState] = useState(false);

  const renderTab = () => {
    //const tags = ["None", "shirt", "pants", "shorts", "jacket", "skirt", "dress", "accessory"];
    const tags = ["UNTAGGED", "SHIRTS", "PANTS", "SHORTS", "JACKETS", "SKIRTS", "DRESSES", "ACCESSORIES"];
    var tabs = [];

    // base case: all items
    tabs.push(
      <div label="ALL">
        <div style={{margin: "3em"}}></div>

        {isAuthenticated &&
        <div className="row">
          {renderAllItems()}
        </div>
        }
        {!isAuthenticated &&
        <div className="disclaimer">
          Please log in to see available items.
        </div>
        }
      </div>
    );

    for (var i = 0; i < tags.length; i++) {
      tabs.push(
        <div label={tags[i]}>
          <div style={{margin: "3em"}}></div>
          
          {isAuthenticated &&
          <div className="row">
            {renderCategoryItems(i)}
          </div>
          }
          {!isAuthenticated &&
          <div className="disclaimer">
            Please log in to see available items.
          </div>
        }
        </div>
      );
    }
    return tabs;
  }

  const renderCategoryTabs = () => {
    
    return(
      <Tabs>
        {renderTab()}
      </Tabs>
    );

  }

  const refreshList = () => {
    console.log('Fetching...')
    fetch('https://dooleydrip.xyz/api/item_post-list/')
    //fetch('http://localhost:8000/api/item_post-list/')
    .then(response => response.json())
    .then(data => {
      setItemList(data);
      }
    )
    console.log('Done fetching...')
    /*
    axios
      .get("/api/item_post-list/")
      .then((res) => setItemList(res.data))
      .catch((err) => console.log(err));
    */

    
  };

  const renderAllItems = () => {
    const newItems = itemList.filter(
      (item) => item.displayed === true
    );

    return newItems.map((item) => (
      <div
        key={item.id}
        className="col-3 square"
        style={{height: '15vw', width: '15vw' }}
      >
        <span
          className={`displayed-item mr-2 ${
            viewCompleted ? "displayed-item" : ""
          }`}
          title={item.description}
        >
          
          <button onClick={() => {viewItem(item);}} style={{cursor:'pointer', width: '15vw', height: '15vw'}}>{item.item_name}</button>
        </span>

        <br></br>
        
      </div>
    ));
  };

  const viewItem = (item) => {
    setUserState(true);

    setActiveItem(item);
    setOpenModal(!openModal);
  }

  const toggle = () => {
    setOpenModal(!openModal);
  }

  const renderCategoryItems = (e) => {
    const newItems = itemList.filter(
      (item) => item.category === e && item.displayed === true
    );

    return newItems.map((item) => (
      <div
        key={item.id}
        className="col-3 square"
        style={{height: '15vw', width: '15vw' }}
      >
        <span
          className={`displayed-item mr-2 ${
            viewCompleted ? "displayed-item" : ""
          }`}
          title={item.description}
        >
          <button onClick={() => {viewItem(item);}} style={{cursor:'pointer', width: '15vw', height: '15vw'}}>{item.item_name}</button>
        </span>

        <br></br>
        
      </div>
    ));
  };


  return (

    <div className="Catalogue">
      <div>
          <div>
            <header>
              <NavBar />
            </header>
          </div>

          <div style={{margin: "3em"}}></div>

          <section className="text-center">

          <div className="row" style={{margin: '3em'}}>
            <div className="col col-sm-10 mx-auto p-0">
              <div className="card p-3">
                  {renderCategoryTabs()}
              </div>
            </div>
          </div>
          
          </section>
          
          

          
          <footer><FootBar/></footer>
          
      </div>       
      {openModal ? (
          <Modal
            activeItem={activeItem}
            toggle={toggle}
            userState={userState}
          />
      ) : null}
        
    </div>

  

);

}

export default Catalogue;
