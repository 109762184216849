import React, { useState, useEffect } from "react";
import "../App.css";
import Modal from "../components/Modal";
import axios from "axios";

import { useAuth0 } from '../react-auth0-spa';
import NavBar from './NavBar';
import FootBar from './FootBar';
import { render } from "react-dom";

function ProfileTest({ history }) {
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
  
  const [userInfo, setUserInfo] = useState({name: '', email: '', picture: null})

  useEffect(() => {
    if(isLoading===false) {refreshList();}
    
  }, [isLoading]);

  // Randy's things
  // Item Modal initial state
  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState({item_name: "", pub_date: "", description: "", price: 0, displayed: false, category: 0, picture: "", user_id: "", email: ""});
  const [viewCompleted, setViewCompleted] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [delState, setDelState] = useState(false); 

  // Item modal, remove Delete and Submit buttons from user view
  // when user clicks on view, change boolean state; when user exits modal, change boolean state
  const [userState, setUserState] = useState(false);
  const [imageState, setImageState] = useState(false);




  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  const createItem = () => {
    const item = { item_name: "", pub_date: "", description: "", price: 0, displayed: false, category: 0, picture: "", user_id: '', email: ''};
    setDelState(false); // do not display Delete button
    setUserState(false); // do not display add item

    setOpenModal(!openModal);
    setActiveItem(item);
  }


  const toggle = () => {
    setOpenModal(!openModal);
  }


  const handleSubmit = (item) => {
    toggle();

    //console.log('ACTIVE ITEM:', activeItem)
    console.log('ITEM:', item)

    var csrftoken = getCookie('csrftoken');
    // editing / updating items
    
    if (item.id) {
      fetch(`https://dooleydrip.xyz/api/item_post-update/${item.id}/`, {
      //fetch(`http://localhost:8000/api/item_post-update/${item.id}/`, {
        method: 'POST',
        headers:{
          'Content-type': 'application/json',
          //'Content-type': 'multipart/form-data',

          'X-CSRFToken': csrftoken,
        },
        body:JSON.stringify(item)
      }).then((response) => {
        refreshList();
      }).catch(function(error) {
        console.log('ERROR:', error)
      })
      return;
    } 
    

    /*
    if (item.id) {
      let form_data = new FormData();

      form_data.append('item_name', item.item_name)
      form_data.append('description', item.description)
      form_data.append('price', item.price)
      form_data.append('displayed', item.displayed)
      form_data.append('category', item.category)
      form_data.append('user_id', user.sub)
      form_data.append('email', user.email)
      form_data.append('picture', item.picture)
      axios
        //.post(`https://dooleydrip.xyz/api/item_post-update/${item.id}/`, item, {
        .post(`http://localhost:8000/api/item_post-update/${item.id}/`, item, {
          headers: {
            //'content-type': 'application/json',
            'content-type': 'multipart/form-data',
            'X-CSRFToken': csrftoken,
          }
        })
        .then((res) => refreshList());
      return;
    }
    */
    
    // posting new items
    /*
    fetch('http://localhost:8000/api/item_post-create/', {
      method: 'POST',
      headers:{
        'Content-type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body:JSON.stringify(item)
    }).then((response) => {
      console.log(response);
      refreshList();
      setActiveItem(item);
    }).catch(function(error) {
      console.log('ERROR:', error)
    })
    */
    let form_data = new FormData();

    form_data.append('item_name', item.item_name)
    form_data.append('description', item.description)
    form_data.append('price', item.price)
    form_data.append('displayed', item.displayed)
    form_data.append('category', item.category)
    form_data.append('user_id', user.sub)
    form_data.append('email', user.email)
    form_data.append('picture', item.picture)
    
    axios.post('https://dooleydrip.xyz/api/item_post-create/', form_data, {
    //axios.post('http://localhost:8000/api/item_post-create/', form_data, {
      headers: {
        //'content-type': 'application/json',
        'content-type': 'multipart/form-data',
        'X-CSRFToken': csrftoken,
      }
    })
        .then(res => {
          console.log(res.data);
          refreshList();
        })
        .catch(err => console.log(err))

  }

  const handleDelete = (item) => {
    toggle();
    
    axios
        .delete(`https://dooleydrip.xyz/api/item_post-delete/${item.id}/`)
        //.delete(`http://localhost:8000/api/item_post-delete/${item.id}/`)
        .then((res) => refreshList());

    /*

    var csrftoken = getCookie('csrftoken')

    fetch(`https://dooleydrip.xyz/api/item_post-delete/${item.id}/`, {
    //fetch(`http://localhost:8000/api/item_post-delete/${item.id}/`, {
        method:'DELETE',
        headers:{
          //'Content-type':'application/json',
          'Content-type': 'multipart/form-data',
          'X-CSRFToken':csrftoken,
        },
      }).then((response) =>{
        refreshList()
      })
      */

  }

  const refreshList = () => {
    console.log('Fetching...')
    fetch('https://dooleydrip.xyz/api/item_post-list/')
    //fetch('http://localhost:8000/api/item_post-list/')
    .then(response => response.json())
    .then(data => {
      setItemList(data);
      }
      
    ) 
  };

  const editItem = (item) => {
    setDelState(true); 
    setUserState(false); 
    setImageState(true);

    setActiveItem(item);
    setOpenModal(!openModal);
  }

  const viewItem = (item) => {
    setUserState(true);

    setActiveItem(item);
    setOpenModal(!openModal);
  }

  const renderDisplayedItems = () => {
    const newItems = itemList.filter(
      (item) => item.displayed === !viewCompleted && item.user_id === user.sub
    );

    return newItems.map((item) => (
      <div
        key={item.id}
        className="col-3 square"
        style={{height: '20vw', width: '15vw' }}
      >
        <span
          className={`displayed-item mr-2 ${
            viewCompleted ? "displayed-item" : ""
          }`}
          title={item.description}
        >
          <button onClick={() => {editItem(item);}} style={{cursor:'pointer', width: '15vw', height: '15vw'}}>{item.item_name}</button>
        </span>

        <br></br>

        <button onClick={() => {viewItem(item);}} style={{width: '15vw', marginBottom: '0.5vw'}}>View Item</button>
        <br></br>
        
      </div>
    ));
  };

  const renderHiddenItems = () => {
    const hidItems = itemList.filter(
      (item) => item.displayed === viewCompleted && item.user_id === user.sub
    );

    return hidItems.map((item) => (
      <div
        key={item.id}
        className="col-3 square"
        style={{height: '20vw', width: '15vw' }}
      >
        <span
          className={`displayed-item mr-2 ${
            viewCompleted ? "displayed-item" : ""
          }`}
          title={item.description}
        >
          <button onClick={() => {editItem(item);}} style={{cursor:'pointer', width: '15vw', height: '15vw'}}>{item.item_name}</button>
        </span>

        <button onClick={() => {viewItem(item);}} style={{width: '15vw', marginBottom: '0.5vw' }}>View Item</button>
        
      </div>
    ));
  };
  
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  
  return (
    <div className="ProfileTest">
      <div>
        <header>
          <NavBar />
        </header>
      </div>
      <div className="body">
        <div>
          {!isAuthenticated &&
            <div className="disclaimer">
              Please log in to see your profile.
          </div>
          }
          {isAuthenticated &&
            <div style={{margin: "3em"}}>
                <div className="row">
                    <div className="col-2">
                      <img
                        src={user && user.picture}
                        alt="Profile"
                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                      />
                      <br></br>
                      <button
                        className="refresher"
                        onClick={() => {refreshList();}}>
                          Refresh Profile 
                      </button>
                    </div>
                    <div className="col text-end">
                        <div className="row"><p>Email: {user && user.email}</p></div>
                    </div>
                    
                </div>

                <div className="divider" style={{height: '12vh'}}></div>

                <div className="row sell fs-2"><strong>SELLING</strong></div>
                <div className="row sell" id="sell-items" style={{textAlign: 'center'}}>

                  <div className="col-3 square order-first" style={{height: '15vw', width: '15vw' }}>
                    <button
                      className="openModalBtn"
                      onClick={() => {
                        
                        createItem();
                    
                      }}
                      style={{height: '15vw', width:'15vw'}}>
                        + 
                    </button>
                  </div>
                  {renderDisplayedItems()}
              
                </div>

                <div className="divider" style={{height: '4vh'}}></div>

                <div className="row hidden fs-2"><strong>HIDDEN</strong></div>
                <div className="row hidden" id="hidden-items" style={{textAlign: 'center'}}>
                  {renderHiddenItems()}
                  
                </div>
                <br></br>
                

            </div>
          }
          

          <footer><FootBar/></footer>

        </div>
      </div>
      {openModal ? (
        <Modal
          activeItem={activeItem}
          toggle={toggle}
          onSave={handleSubmit}
          onDelete={handleDelete}
          delState={delState}
          userState={userState}
          user_id={user.sub}
          email={user.email}
        />
      ) : null}

    </div>

  );
}

export default ProfileTest;