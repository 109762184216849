import React from 'react';
import { useAuth0 } from '../react-auth0-spa';
import logo from './assets/img/logo.png';
import './assets/css/styles.css';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Profile from './Profile';
import PrivateRoute from './PrivateRoute';

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div className="background-image">
        <nav class="navbar navbar-dark bg-dark"></nav>
        <nav class="navbar navbar-dark bg-dark navbar-expand-lg" id="mainNav">
            <div class="container content">
              <img src={logo} alt="logo" className="logo" />
              <a class="navbar-brand" href="/">Dooley Drip</a>
              <button data-bs-toggle="collapse" data-bs-target="#navbarResponsive" class="navbar-toggler navbar-toggler-right" type="button" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><i class="fa fa-align-justify"></i></button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarResponsive">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a class="nav-link mt-3" href="/">Home</a></li>
                        <li class="nav-item"><a class="nav-link mt-3" href="/about">About</a></li>
                        <li class="nav-item"><a class="nav-link mt-3" href="/team">Team</a></li>
                        <li class="nav-item"><a class="nav-link mt-3" href="/catalogue">Catalogue</a></li>
                        {!isAuthenticated && (
                        <a className="nav-link mt-3" href="/" onClick={()=>loginWithRedirect({screen_hint: 'signup', prompt:'login'})}>Login/Sign Up</a>
                        )
                        }
                        {isAuthenticated && (
                        <ul class="navbar-nav">
                          <li class="nav-item"><a class="nav-link mt-3" href="/user-profile">Profile</a></li>
                          
                          <li class="nav-item"><a class="nav-link mt-3" href="/" onClick={() => logout({returnTo: window.location.origin,})}>Logout</a></li>
                        </ul>
                        )
                        }

                    </ul>

                    {/*isAuthenticated && (
                      <ul class="navbar-nav">
                        <li class="nav-item"><a class="nav-link mt-3" href="/">Home</a></li>
                        <li class="nav-item"><a class="nav-link mt-3" href="/user-profile">Profile</a></li>
                        <li class="nav-item"><a class="nav-link mt-3" href="/test">Test</a></li>
                        <li class="nav-item"><a class="nav-link mt-3" href="/" onClick={() => logout()}>Log out</a></li>
                      </ul>
                    )
                    */}
                </div>
            </div>
        </nav>
    </div>
  );
};

export default NavBar;