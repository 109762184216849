import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from '../../react-auth0-spa';
import Welcome from "../Welcome";
import Profile from "../Profile";
import Test from "../Test";
// import Modal from "../Modal";

// import ProductPage from "../ProductPage.js";
import Catalogue from "../Catalogue";
import ProfileTest from "../ProfileTest.js";
import Aboutpage from "../About";
import Team from "../Team";
// WARNING: path="profile" does not work and results in a white page of death - Harry

const AppRouter  = () => {
     console.log('Router');
     return(
       <Router>
         <Routes>
            <Route path="/" element={<Welcome />}/>
            <Route path="catalogue" element={<Catalogue />}/>
            <Route path="user-profile" element={<ProfileTest />}/>
            <Route path="about" element={<Aboutpage />}/>
            <Route path="team" element={<Team />}/>
            <Route path="*" element={<main style={{ padding: "1rem" }}><p>404 Page Not Found</p></main>}/>
         </Routes>
       </Router>
     );
}
export default AppRouter;   
