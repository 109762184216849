import React, { Component } from 'react';
import './assets/css/styles.css';
import '../App.css';
//import { useAuth0 } from "@auth0/auth0-react";
import { useAuth0 } from '../react-auth0-spa';
import NavBar from './NavBar';
import FootBar from './FootBar';

const Aboutpage = () => {
  const { isAuthenticated } = useAuth0();

  return (
      
    <div className="Welcome">
        {console.log(isAuthenticated)}
        <div>
            <header>
            <NavBar />
            </header>
        </div>

          <div className="main-body about-body" style={{textAlign: 'center'}}>
            <div className="row">
              <div className="col">
                <h1 className="row"><strong>No wasted closet space!</strong></h1>
                <div className="line"></div>
                <br></br>
                <p>Dooley Drip exists to promote reusability and provide a safe and secure way where students can share, exchange, and rent clothing products and accessories at low costs.</p>
                <div className="line"></div>
              </div>
            </div>
          </div>

          <div className="about-content">
            <p>Project Eagle Row will help relieve students’ stress of acquiring themed clothing for events through a website-based marketplace. Although informal markets for themed clothes already exist in each fraternity/sorority, the goal is to establish a single marketplace which would have a larger selection and easy, online access. For example, if Bid Day is themed “red hot,” participants without the required red clothes and fiery accessories would have to find the appropriate attire by scouring the internet or spend hours asking friends. If the participant chose to purchase themed items, they might end up with a red wig or sunglasses sprouting flames that they are unlikely to wear a second time. Project Eagle Row would provide a centralized marketplace where these items could be sold or lent to future partygoers. 
                Buyers would benefit from the convenience of having themed merchandise available in one location and lower prices while sellers would be able to profit by passing on unneeded items.
                </p>
                <div>
                <button>
                   <a href="/team"> Meet the Team</a>
                </button>
                </div>
          </div>

        <footer><FootBar /></footer>
    </div>
  );
};

export default Aboutpage;