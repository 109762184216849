import React, { Component } from 'react';
import './assets/css/styles.css';
import '../App.css';
//import { useAuth0 } from "@auth0/auth0-react";
import { useAuth0 } from '../react-auth0-spa';
import NavBar from './NavBar';
import FootBar from './FootBar';

const Welcome = () => {
  //const { isAuthenticated } = useAuth0();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
      
    <div className="Welcome">
        {console.log(isAuthenticated)}
        <div>
            <header>
            <NavBar />
            </header>
        </div>

          <div className="main-body" style={{textAlign: 'center'}}>
            <div className="row">
              <div className="col">
                <h1 className="row"><strong>Convenient Clothing at Your Fingertips</strong></h1>
                <div className="line"></div>
                <br></br>
                {!isAuthenticated && (
                  <div className="main-body-two" style={{textAlign: 'center'}}>
                    
                    {/* <div className="row"><p>Click "Log in" at the top to sign up</p></div> */}
                    <div className="finder">

                      <div>
                        <h3>New to the site? Sign up! Already a member? Log in!</h3>
                       <button><a href="/" onClick={()=>loginWithRedirect({screen_hint: 'signup', prompt:'login'})}>Join Us!</a></button>
                      </div>
                    </div>
                  </div>
                )}
                {isAuthenticated && (
                  <div className="main-body-two" style={{margin: "3em", textAlign: 'center'}}>
                    <h3>Already logged in? Check out some items!</h3>
                    <div className="row"><p>View your Profile or browse items using the Catalogue in the navigation bar at the top of the site</p></div>
                      <div className="finder">
                        <button><a href="/about">About Us!</a></button>
                        <button><a href="/team">Meet the Team!</a></button>
                      </div>
                  </div>
                )}
                
              </div>
            </div>
          </div>

        <footer><FootBar /></footer>
    </div>
  );
};

export default Welcome;