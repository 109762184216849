import React from 'react';


const FootBar = () => {


  return (
    <div className="fixed-bottom">
        <footer>
            <div>
            <nav className="navbar navbar-dark bg-dark navbar-expand-md navigation-clean-search">
                <div className="container-fluid"><a className="navbar-brand" href="/">Dooley Drip</a>
                    <ul className="navbar-nav">
                    <li className="nav-item nav-link">copyright 2021</li>
                    </ul>
                    <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link" href="/about">contact us</a></li>
                    </ul>
                </div>
            </nav>
            </div>
        </footer>
    </div>
    
  );
};

export default FootBar;