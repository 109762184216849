import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  FormControl,
  FormText
} from "reactstrap";
import {
  DropdownButton,
  Dropdown,
  Image
} from "react-bootstrap";
import * as ReactDOM from "react-dom";
import axios from "axios";

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.activeItem,
      delState: this.props.delState,
      userState: this.props.userState,
    };
  }

  handleSelect = (e) => {
    const activeItem = { ...this.state.activeItem, ["category"]: e };
    this.setState({ activeItem });
    
  };

  handleImageChange = (e) => {
    e.preventDefault();
    console.log("File uploaded");
    console.log(e.target.files[0]);
    this.setState({
      image: e.target.files[0]
    })
  };

  handleChange = (e) => {
    console.log("test4");
    let { name, value } = e.target;
    console.log("test5");

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    else if (e.target.type === "file") {
      //console.log(e.target.files[0]);
      value = e.target.files[0];
      //console.log("Saved file");\
      console.log("test6");
    }
    

    const activeItem = { ...this.state.activeItem, [name]: value };
    //console.log(activeItem);

    this.setState({ activeItem: activeItem });
    //this.setState({ activeItem });
    //console.log("State set");
    //console.log(this.state.activeItem);
  };


  render() {
    const { toggle, onSave, onDelete, delState, userState, user_id, email } = this.props;
    const tags = ["None", "shirt", "pants", "shorts", "jacket", "skirt", "dress", "accessory"];

    return (
      <Modal size="lg" isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>{this.state.activeItem.item_name}</ModalHeader>
        {userState &&
        <ModalBody>
          <div className="row">
            {/*<div className="col-6">
              {console.log(this.state.activeItem.picture)}
              //<Image src={this.state.activeItem.picture} style={{height: "25vw", width: "25vw"}}/>}
            </div>*/}

            <div className="col-6">
              <strong>Product Name:</strong>
              <br></br>
              <p className="item-name">{this.state.activeItem.item_name}</p>

              <strong>Price:</strong>
              <br></br>
              <p className="price">${this.state.activeItem.price}</p>
              <br></br>

              <strong>Description:</strong>
              <p className="description">{this.state.activeItem.description}</p>

              <strong>Tag:</strong>
              <br></br>
              <p className="item-tag">{tags[this.state.activeItem.category]}</p>

              <strong>Date Published:</strong>
              <br></br>
              <p className="item-published">{(this.state.activeItem.pub_date).slice(0,10)}</p>

              <strong>Contact Email:</strong>
              <br></br>
              <p className="item-email">{this.state.activeItem.email}</p>

            </div>
          </div>
        </ModalBody>
        }

        
        {!userState &&
        <ModalBody>
          <div className="row">
          {/*
            <div className="col-6">
            <Form>
              <FormGroup>
                <Label for="picture">
                  File
                </Label>

                <Input
                  id="picture"
                  name="picture"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  defaultValue={this.state.activeItem.picture}
                  onChange={this.handleChange}
                />
{console.log("test1")}


                <FormText>
                  Upload an image of your product. Supported formats: .png, .jpeg, .jpg
                </FormText>

                <Image src={this.state.activeItem.picture} style={{height: "25vw", width: "25vw"}} thumbnail/>
{console.log("test2")}
              </FormGroup>
            
              <FormGroup className="e-disabled" >
                <Input
                  type="text"
                  id="image-preview"
                  name="picture"
                  defaultValue={this.state.activeItem.picture}
                  onChange={this.handleChange}
                  disabled={true}
                  hidden={false}
                />
              </FormGroup>
            
{console.log("test3")}

            </Form>
            
            </div>
            */}

            <div className="col-6">
            <Form>

              <FormGroup>
                <Label for="item-name">Name</Label>
                <Input
                  type="text"
                  id="item-name"
                  name="item_name"
                  value={this.state.activeItem.item_name}
                  onChange={this.handleChange}
                  placeholder="Enter Item Name"
                />
              </FormGroup>

              <FormGroup>
                <Label for="price">Price</Label>
                <Input
                  type="number"
                  id="price"
                  name="price"
                  value={this.state.activeItem.price}
                  onChange={this.handleChange}
                  placeholder="Enter Item Price"
                />
              </FormGroup>

              <FormGroup>
                <Label for="description">Description and Size</Label>
                <Input
                  type="text"
                  id="description"
                  name="description"
                  value={this.state.activeItem.description}
                  onChange={this.handleChange}
                  placeholder="Enter Item Description"
                />
              </FormGroup>

              <FormGroup>
                <Label for="category">Category</Label>
                <DropdownButton title={tags[this.state.activeItem.category]} onSelect={this.handleSelect}>
                  <Dropdown.Item eventKey={0}>None</Dropdown.Item>
                  <Dropdown.Item eventKey={1}>shirt</Dropdown.Item>
                  <Dropdown.Item eventKey={2}>pants</Dropdown.Item>
                  <Dropdown.Item eventKey={3}>shorts</Dropdown.Item>
                  <Dropdown.Item eventKey={4}>jacket</Dropdown.Item>
                  <Dropdown.Item eventKey={5}>skirt</Dropdown.Item>
                  <Dropdown.Item eventKey={6}>dress</Dropdown.Item>
                  <Dropdown.Item eventKey={7}>accessory</Dropdown.Item>
                </DropdownButton>
              </FormGroup>

              <FormGroup className="e-disabled" >
                <Input
                  type="number"
                  id="category"
                  name="category"
                  value={this.state.activeItem.category}
                  onChange={this.handleChange}
                  disabled={true}
                  hidden={true}
                />
              </FormGroup>

              <FormGroup>
                <Label for="user-email">Contact Email</Label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  placeholder="Enter Email"
                  disabled={true}
                />
              </FormGroup>

              

              <FormGroup className="e-disabled" >
                <Label for="item-published">Published</Label>
                <Input
                  type="text"
                  id="item-published"
                  name="pub_date"
                  value={this.state.activeItem.pub_date}
                  onChange={this.handleChange}
                  placeholder="Autofill Published Date"
                  disabled={true}
                />
              </FormGroup>

              <FormGroup className="e-disabled" >
                <Input
                  type="text"
                  id="user-id"
                  name="user_id"
                  value={user_id}
                  onChange={this.handleChange}
                  disabled={true}
                  hidden={true}
                />
              </FormGroup>

              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="displayed"
                    checked={this.state.activeItem.displayed}
                    onChange={this.handleChange}
                  />
                  Displayed
                </Label>
              </FormGroup>
            </Form>
            </div>
          </div>
        </ModalBody>
        }
        {!userState &&
        <ModalFooter>
          {this.state.delState &&
          <Button
            color="danger"
            onClick={() => onDelete(this.state.activeItem)}
          >
            Delete
          </Button>
          }

          <Button
            color="success"
            onClick={() => onSave(this.state.activeItem)}
          >
            Save
          </Button>
        </ModalFooter>
        }
      </Modal>
    );
  }
}