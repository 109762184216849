import React, { Component } from 'react';
import './assets/css/styles.css';
import '../App.css';
//import { useAuth0 } from "@auth0/auth0-react";
import { useAuth0 } from '../react-auth0-spa';
import NavBar from './NavBar';
import FootBar from './FootBar';
import male from "./assets/img/male.jpg";
import female from "./assets/img/female.png";
/*
// Optional Team Pictures
import ben from "../img/ben.JPG";
import harry from "../img/harry.JPG";
import allen from "../img/allen.JPG";
import randy from "../img/randy.JPG";
import jesse from "../img/jesse.JPG";
import siwei from "../img/siwei.JPG";
import elyse from "../img/elyse.JPG";
*/

const Team = () => {
  const { isAuthenticated } = useAuth0();

  return (
      
    <div className="Welcome">
        {console.log(isAuthenticated)}
        <div>
            <header>
            <NavBar />
            </header>
        </div>

          <div className="team-body" style={{textAlign: 'center'}}>
               <div className="container">
                <div className="row justify-content-between pt-5">
                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                    <div class="row g-0">
                        <div class="col-md-4">
                        <img src={male} class="img-fluid rounded-start"/>
                        </div>
                        <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title">Ben Gibbons - Team Member</h5>
                            <p class="card-text">Ben is a Senior at Emory University, majoring in Computer Science and Psychology. I enjoy integrating computer science into research and data collection, which is why I am currently interested in the field data science. I have also enjoyed this project enough that I am considering software development as well.</p>
                            
                        </div>
                     </div>
                </div>
                    </div> 

                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                            <img src={male} class="img-fluid rounded-start" alt="male-image" />
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Harry Feng - Scrum Master</h5>
                                <p class="card-text">Harry Feng is a senior at Emory University, majoring in Neuroscience and Computer Science. He is on the premed track and does kidney cancer research in the Petros Lab. He is also actively interested in software development and Dooleydrip has helped consolidate that passion.</p>
                                
                            </div>
                        </div>
                    </div>
                    </div> 

                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                            <img src={male} class="img-fluid rounded-start" alt="male-image" />
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Allen Chak - Team Member</h5>
                                <p class="card-text">Allen is a Senior at Emory Univeristy, majoring in Computer Sciecnce and minoring in Applied Mathmatics. My focus was on AWS frontend and back end hosting as well as frontend CSS and Bootstrap design. </p>
                            </div>
                        </div>
                    </div>
                    </div> 

                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                            <img src={male} class="img-fluid rounded-start" alt="male-image" />
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Randy Truong - Team Member</h5>
                                <p class="card-text">Randy Truong is a senior at Emory College of Arts and Sciences, majoring in Computer Science and Quantitative Sciences in Anthropology. He is involved in human-computer interaction (HCI) research and working on a CS Honors thesis under Dr. Emily Wall. Working on Dooley Drip also helped validate his interest in software development.</p>
                            </div>
                        </div>
                    </div>
                    </div> 

                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                            <img src={male} class="img-fluid rounded-start" alt="male-image" />
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Jesse Sun - Team Member</h5>
                                <p class="card-text">Jesse Sun is a junior at Emory University, majoring in Applied Math and Stats. He is involved with mathematical modeling research on Covid-19. He is also interested in learning more about software development and its various applications, which Dooley Drip has helped to foster.</p>                             
                            </div>
                        </div>
                    </div>
                    </div> 

                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                            <img src={female} class="img-fluid rounded-start" alt="male-image" />
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Siwei Xu - Team Member</h5>
                                <p class="card-text">Siwei Xu is a senior at Emory university, majoring in math and CS. She is interested in training deep neural network to solve partial differential equations. She hopes students find the website useful when it comes to the Halloween season.</p>
                            </div>
                        </div>
                    </div>
                    </div> 

                    <div class="card mb-3" style={{maxWidth: '540px', height: '300px'}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                            <img src={female} class="img-fluid rounded-start" alt="male-image" />
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Elyse Toder - Project Owner</h5>
                                <p class="card-text">Elyse Toder is a senior at Emory university, majoring in CS and Art History with a Visual Arts Concentration. She is interested in UI\UX and product design. She hopes that students really enjoy our website!</p>
                            </div>
                        </div>
                    </div>
                    </div> 
                </div>
               </div>
            </div>
        <footer><FootBar /></footer>
    </div>
  );
};

export default Team;